var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fabric-orders-container rounded elevation-3",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("รายการใบสั่งผ้า")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-base-line"},[_c('search-box',{attrs:{"disabled":_vm.loading},on:{"on-search":function($event){return _vm.fetchFabricOrders(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mt-5 fabric-table",attrs:{"headers":_vm.headers,"items":_vm.fabricOrders,"items-per-page":-1,"dense":"","loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeUserFormat")(item.createdAt))+" ")]}},{key:"item.orderIds",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getOrderIds(item.prototypes)),function(orderId,index){return _c('v-chip',{key:("chip-" + index),staticClass:"ma-1",attrs:{"color":"secondary","text-color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(orderId)+" ")])})}},{key:"item.collections",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getCollections(item.prototypes)),function(collection,index){return _c('v-chip',{key:("chip-" + index),staticClass:"ma-1",attrs:{"color":"secondary","text-color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(collection)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showFabricOrder(item)}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,true)}),_c('div',{staticClass:"text-center mt-5"},[_c('v-pagination',{attrs:{"length":_vm.filters.pages,"total-visible":7,"color":"secondary"},on:{"input":function($event){return _vm.fetchFabricOrders()}},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }