<template>
  <v-container
    class="fabric-orders-container rounded elevation-3"
    fluid>
    <v-row
      justify="space-between"
      align="center">
      <v-col cols="6">
        <h2>รายการใบสั่งผ้า</h2>
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-row justify-end align-base-line">
          <search-box
            v-model="filters.search"
            :disabled="loading"
            @on-search="fetchFabricOrders(true)" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="mt-5 fabric-table"
          :headers="headers"
          :items="fabricOrders"
          :items-per-page="-1"
          dense
          :loading="loading"
          hide-default-footer>
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateTimeUserFormat() }}
          </template>
          <template #[`item.orderIds`]="{ item }">
            <v-chip
              v-for="(orderId, index) in getOrderIds(item.prototypes)"
              :key="`chip-${index}`"
              class="ma-1"
              color="secondary"
              text-color="white"
              small
              label>
              {{ orderId }}
            </v-chip>
          </template>
          <template #[`item.collections`]="{ item }">
            <v-chip
              v-for="(collection, index) in getCollections(item.prototypes)"
              :key="`chip-${index}`"
              class="ma-1"
              color="secondary"
              text-color="white"
              small
              label>
              {{ collection }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              icon
              @click="showFabricOrder(item)">
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center mt-5">
          <v-pagination
            v-model="filters.page"
            :length="filters.pages"
            :total-visible="7"
            color="secondary"
            @input="fetchFabricOrders()">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FabricOrdersProvider from '@/resources/FabricOrdersProvider'
import SearchBox from '@/components/SearchBox.vue'

const FabricOrdersService = new FabricOrdersProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      loading: false,
      fabricOrders: [],
      filters: {
        search: '',
        page: 1,
        pages: 1,
        limit: 10
      },
      headers: [
        {
          text: 'วันที่ทำรายการ',
          value: 'createdAt',
          align: 'center',
          width: '200px',
          sortable: false
        },
        {
          text: 'สั่งผ้าโดย',
          value: 'createdBy.email',
          align: 'center',
          width: '200px',
          sortable: false
        },
        {
          text: 'เลขที่ใบสั่งผ้า',
          value: 'id',
          align: 'center',
          width: '200px',
          sortable: false
        },
        {
          text: 'ออเดอร์',
          value: 'orderIds',
          sortable: false
        },
        {
          text: 'คอลเลคชั่น',
          value: 'collections',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          width: '100px',
          sortable: false
        }
      ],
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ]
    }
  },
  created () {
    this.fetchFabricOrders()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setFabricOrder: 'Prototypes/setFabricOrder'
    }),
    async fetchFabricOrders () {
      try {
        this.loading = true
        this.fabricOrders = []

        const { data } = await FabricOrdersService.getAll({
          search: this.filters.search,
          page: this.filters.page,
          limit: this.filters.limit
        })

        this.filters.pages = data.pages

        this.fabricOrders = data.results
      } catch (error) {
        console.error('fetchFabricOrders', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getOrderIds (prototypes) {
      return prototypes.map((p) => p.order.orderId)
    },
    getCollections (prototypes) {
      return prototypes.map((p) => p.sketch.collectionName).filter((c) => !!c)
    },
    showFabricOrder (item) {
      this.setFabricOrder(item)
      window.open(this.$router.resolve({ name: 'ExportFabricOrder' }).href, '_blank')
    }
  }
}
</script>

<style scoped>
.fabric-orders-container {
  background-color: white;
}
.cursor-pointer {
  cursor: pointer;
}
.table-wrap {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 610px;
  overflow-y: scroll;
}
.detail-wrap {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 610px;
  overflow-y: scroll;
}

</style>
